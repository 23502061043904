import { Link, useIntl } from 'gatsby-plugin-intl'
import React from 'react'
import Layout from '../../components/Layout/index'
import PressRelease from '../../components/PressRelease'
import SEO from '../../components/seo'
import Footer from '../../modules/Footer'
import Header from '../../modules/Header'

import Image from '../../images/news/press-release-itb2023.jpg'
import metaDescription from '../../messages/pageMetaDescriptions.lang'
import messages from '../../messages/pageTitles.lang'

import { newsList } from '../../components/NewsPage/data'

const IndexPage = () => {
  const { formatMessage, locale } = useIntl()

  return (
    <Layout footer={<Footer />} header={<Header withTeaser />}>
      <SEO
        title={formatMessage(messages.TitleNewsItb2023)}
        imageAlt={formatMessage(messages.TitleNewsItb2023)}
        description={formatMessage(metaDescription.NewsItb2023Description)}
        image={Image}
        lang={locale}
      />
      <PressRelease
        article={newsList.itb2023}
        articleLinks={{
          link: (
            <Link to="/contact/">
              {`https://www.freshcells.de/${locale}/contact/`}
            </Link>
          )
        }}
      />
    </Layout>
  )
}

export default IndexPage
