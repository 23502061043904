// @flow

import React from 'react'
import classNames from 'classnames'
import { useIntl } from 'gatsby-plugin-intl'
import { applyStylingHoc, type StyleOuterProps, type StyleProps } from '@fcse/tsbob/dist/lib/styling'
import { Container } from '../../../components/basic/Grid'

import messages from './SocialMedia.lang'
import baseStyles from './SocialMedia.scss'

import imgInsta from '../../../images/socials/instagram.svg'

type Props = {
  ...StyleProps
}

const socialMediaList = [
  {
    name: 'Facebook',
    icon: 'icon-facebook',
    link: 'https://www.facebook.com/freshcells'
  },
  {
    name: 'Instagram',
    image: imgInsta,
    link: 'https://www.instagram.com/freshcells_systems_engineering'
  },
  {
    name: 'LinkedIn',
    icon: 'icon-linkedin',
    link: 'https://www.linkedin.com/company/freshcells-systems-engineering-gmbh/'
  },
  {
    name: 'Xing',
    icon: 'icon-xing',
    link: 'https://www.xing.com/companies/freshcellssystemsengineeringgmbh'
  }
]

const SocialMedia = ({ styles }: Props) => {
  const { formatMessage } = useIntl()

  return (
    <div className={styles.root}>
      <Container fluid>
        <h3 className={styles.title}>{formatMessage(messages.title)}</h3>
        <h4 className={styles.subtitle}>{formatMessage(messages.subtitle)}</h4>
        <div className={styles.list}>
          {socialMediaList.map((item, i) => (
            <a className={styles.item} key={i} href={item.link} target="_blank" rel="noopener noreferrer">
              <span className={classNames(styles.icon, item.icon && item.icon)}>
                {item.image && <img src={item.image} alt="" className={styles.itemIcon} />}
              </span>
              <span className={styles.name}>{`${formatMessage(messages.findUs)} ${item.name}`}</span>
            </a>
          ))}
        </div>
      </Container>
    </div>
  )
}
export default applyStylingHoc(baseStyles)<$Diff<Props, StyleProps> & StyleOuterProps, Function>(SocialMedia)
